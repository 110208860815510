import { GetDeliverArgs, GetDeliverList, getDeliveryCount } from "@/api/LogisticApi";
import { ShopDownloadDeliveryList } from '@/api/DownloadApi';
import { LogisticStatus, OrderType, ProductType } from "@/common/enum";
import { FormatterDateTime } from "@/common/formatter";
import { CountService } from "@/services/Logistic/CountService";
import MerchantService from "@/services/Merchant/MerchantService";
import { reactive, ref } from "vue";
import TableSortClick from "@/views/Common/SortTable";
const { setCurrentSort, headerSortClick } = TableSortClick();
import { ScrollToTop } from "@/common/utils";
setCurrentSort({
    prop: "purchaseTime",
});
const columnIndexs = [0, 1, 8, 9, 10];
export const datas = reactive(new Array());
export const totalCount = ref(0);
export const pageSize = ref(20);
export const page = ref(1);
export const headerPurchaseTime = ref('');
const spans = ref(new Array());
export const queryArgs = reactive(new GetDeliverArgs());
queryArgs.orderId = '';
queryArgs.deliverOrder = "";
queryArgs.page = 1;
queryArgs.pageSize = 10;
queryArgs.productName = "";
queryArgs.recipientName = "";
queryArgs.recipientPhone = "";
queryArgs.sortDirection = 0;
queryArgs.purchaseTimeStart = "";
queryArgs.purchaseTimeEnd = "";
queryArgs.productType = 0;
queryArgs.productNo = "";
const calcSpans = (list) => {
    list = list.map((item) => item.orderId);
    let proOrderId = 0;
    let len = 0;
    let rowIndex = 0;
    list.forEach((item, index) => {
        if (index == 0) {
            proOrderId = item;
            len = 1;
            rowIndex = 0;
        }
        else if (proOrderId == item) {
            len++;
        }
        else {
            spans.value.push({
                rowIndex,
                rowSpan: len
            });
            rowIndex = index;
            proOrderId = item;
            len = 1;
        }
        if (index === (list.length - 1)) {
            spans.value.push({
                rowIndex,
                rowSpan: len
            });
        }
    });
};
export const objectSpanMethod = ({ row, column, rowIndex, columnIndex, }) => {
    const span = spans.value.find(item => item.rowIndex == rowIndex);
    if (columnIndexs.indexOf(columnIndex) > -1) {
        if (span) {
            return {
                rowspan: span.rowSpan,
                colspan: 1,
            };
        }
        else {
            return {
                rowspan: 0,
                colspan: 0,
            };
        }
    }
};
export async function queryData() {
    MerchantService.ShopDetailsAsync().then(async () => {
        queryArgs.status = LogisticStatus.Wait;
        queryArgs.merchantId = MerchantService.GetMerchantDetail().id;
        queryArgs.orderType = OrderType.PurchaseTime;
        const res = await GetDeliverList({
            ...queryArgs,
            orderId: +(queryArgs.orderId || '')
        });
        if (res.errno == 0) {
            datas.splice(0, datas.length);
            const list = res.data.list || [];
            spans.value = [];
            calcSpans(list);
            list.forEach(item => {
                item.showMore = false;
                datas.push(item);
            });
            getDeliveryCount().then((res) => {
                CountService.ChangeTipCount(res);
            });
            totalCount.value = res.data.total;
        }
        ScrollToTop();
    });
}
export function formatAfterSalesStatus(row, col, cell) {
    switch (cell) {
        case 0:
            return '无售后';
        case 1:
            return '售后中';
        default:
            return '售后完成';
    }
}
export function formatPurchaseTime(row) {
    return FormatterDateTime(row.purchaseTime, "yyyy-MM-dd hh:mm:ss");
}
export const headerClick = async (column) => {
    if (await headerSortClick(column)) {
        queryArgs.sortDirection = !queryArgs.sortDirection * 1;
        queryArgs.page = 1;
        queryData();
    }
};
export const changeTime = (val) => {
    queryArgs.purchaseTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
    queryArgs.purchaseTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
};
export const showDlg = ref(false);
export const dialogInfo = reactive({
    title: "",
    component: "",
    productType: ProductType.entity
});
export const orderId = ref();
export const toShipping = (row) => {
    row.productType && (dialogInfo.productType = row.productType);
    dialogInfo.title = '发货处理（订单号' + row.orderId + '）';
    dialogInfo.component = 'ShippingHandler';
    orderId.value = row.orderId;
    showDlg.value = true;
};
export const remarks = ref("");
export const selectedId = ref();
// 添加、修改备注
export const addRemarks = (row) => {
    dialogInfo.title = `${row.remarks ? '修改' : '添加'}备注`;
    remarks.value = row.remarks || '';
    dialogInfo.component = 'Remarks';
    selectedId.value = row.deliverId;
    showDlg.value = true;
};
// 发货单下载
export const expDeliver = () => {
    ShopDownloadDeliveryList({
        ...queryArgs,
        orderId: 0,
        page: 0,
        pageSize: 0
    }, '待发货商品列表.xlsx');
};
