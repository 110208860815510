import { GetAuthToken } from "@/api/Request";
import ShopService from "@/services/Shop/ShopService";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { computed, reactive, ref } from "vue";
export default function PendingBatch() {
    let loadingInstance;
    const beforeOrderUpload = (file) => {
        const names = file.name.split(".");
        const isxlsx = names[names.length - 1] === "xlsx";
        if (!isxlsx) {
            ElMessageBox.alert("文件格式有误，上传失败，您可以下载模板文件修改后再次上传", "批量导入物流单号");
        }
        loadingInstance = ElLoading.service({ fullscreen: true });
        return isxlsx;
    };
    const importPageSize = ref(10);
    const showImportResult = ref(false);
    const importErrorList = reactive(new Array());
    const importPageNum = ref(1);
    const pageImportErrorData = reactive(new Array());
    const importErrorNum = ref(0);
    const successNum = ref(0);
    const afterServiceNum = ref(0); // 售后中数量
    const uploadSuccess = (val) => {
        loadingInstance.close();
        if (val.errno == 0) {
            ElMessage.success("导入成功");
            showImportResult.value = true;
            importErrorNum.value = val.data.errorNum;
            successNum.value = val.data.successNum;
            afterServiceNum.value = val.data.afterServiceNum;
            importErrorList.splice(0, importErrorList.length);
            for (let i = 0; i < val.data.deliverErrorList.length; i++) {
                importErrorList.push(val.data.deliverErrorList[i]);
            }
            importPageNum.value = 1;
            computPageImportData();
        }
        else {
            ElMessage.error(val.msg);
        }
    };
    const computPageImportData = () => {
        pageImportErrorData.splice(0, pageImportErrorData.length);
        for (let i = (importPageNum.value - 1) * importPageSize.value; i < (importPageNum.value) * importPageSize.value; i++) {
            if (i < importErrorList.length) {
                pageImportErrorData.push(importErrorList[i]);
            }
        }
    };
    const handleImportCurrentChange = (val) => {
        importPageNum.value = val;
        computPageImportData();
    };
    const handleImportSizeChange = (val) => {
        importPageSize.value = val;
        computPageImportData();
    };
    const CustomHeader = computed(() => {
        const token = GetAuthToken();
        return {
            Authorization: token,
            authStore: ShopService.GetCurrentShop().id
        };
    });
    const downloadTemplate = () => {
        window.open("/导入物流单号模板.xlsx");
    };
    return {
        beforeOrderUpload,
        uploadSuccess,
        CustomHeader,
        downloadTemplate,
        showImportResult,
        importErrorList,
        importErrorNum,
        successNum,
        afterServiceNum,
        importPageSize,
        pageImportErrorData,
        handleImportCurrentChange,
        handleImportSizeChange
    };
}
