import { reactive, ref } from "vue";
const tableRef = ref(null);
export default function TableSortClick() {
    const currentSort = reactive({
        prop: '',
        order: ''
    });
    const setCurrentSort = (obj) => {
        currentSort.prop = obj.prop;
        currentSort.order = obj.order || "descending";
    };
    const headerSortClick = (column) => {
        if (currentSort.prop == column.prop && currentSort.order == column.order) {
            return false;
        }
        if (column.prop == null) {
            tableRef.value.sort(currentSort.prop, currentSort.order);
            return false;
        }
        setCurrentSort(column);
        return true;
    };
    return {
        tableRef,
        currentSort,
        setCurrentSort,
        headerSortClick
    };
}
