import { defineComponent } from 'vue';
import { queryData, remarks, selectedId, showDlg } from '../ToBeShipped';
import { editRemarks } from '@/api/LogisticApi';
export default defineComponent({
    setup() {
        const onSubmit = () => {
            editRemarks({ deliverId: selectedId.value, remarks: remarks.value }).then((res) => {
                showDlg.value = false;
                queryData();
            });
        };
        const onCancel = () => {
            showDlg.value = false;
        };
        return {
            remarks,
            onCancel,
            onSubmit,
        };
    },
});
