import { reactive, ref } from "vue";
import { ElMessage } from 'element-plus';
import { getOrderDeliver, AddDeliver } from "@/api/LogisticApi";
import { showDlg, queryData } from "../ToBeShipped";
class OrderGoods {
}
export default class ShippingHandler {
    constructor() {
        this.orderGoodsList = reactive(new Array());
        this.multipleSelection = reactive([]);
        this.multipleTableRef = ref();
        this.editForm = ref(null);
        this.deliverArr = ref([]);
        this.editFormObj = reactive({
            type: 1,
            newOrder: "",
            newName: "",
            deliverRemark: ''
        });
        this.rules = {
            newName: [
                {
                    required: true,
                    message: '请输入新的物流公司名称',
                    trigger: 'blur',
                },
                { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' },
            ],
            newOrder: [
                {
                    required: true,
                    message: '请输入新的物流单号',
                    trigger: 'blur',
                },
                { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
            ],
            deliverRemark: [
                {
                    required: true,
                    message: '补充说明不能为空',
                    trigger: 'blur',
                },
                { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
            ],
            unrequired: [{ required: false }],
        };
        this.selectable = (row) => {
            if (row.status == 1) {
                return true;
            }
            else {
                return false;
            }
        };
        this.initOrderGoodsList = (orderId) => {
            getOrderDeliver(orderId.value).then((res) => {
                if (res.errno == 0) {
                    res.data.forEach((item) => {
                        this.orderGoodsList.push(item);
                    });
                    if (this.orderGoodsList.length == 1) {
                        this.multipleTableRef.value?.toggleRowSelection(this.orderGoodsList[0], true);
                    }
                }
            });
        };
        this.handleSelectionChange = (val) => {
            this.deliverArr.value = val.map((item) => item.deliverId);
        };
        this.handleChangeType = () => {
            this.editForm.value.resetFields();
            this.editFormObj.newName = '';
            this.editFormObj.newOrder = '';
            this.editFormObj.deliverRemark = '';
        };
        this.onSubmit = (row) => {
            if (!this.deliverArr.value.length) {
                ElMessage.warning('请选择任意商品进行处理');
                return;
            }
            this.editForm.value.validate((valid) => {
                if (valid) {
                    const args = {
                        deliverId: this.deliverArr.value,
                        type: this.editFormObj.type,
                        expressName: this.editFormObj.newName,
                        expressNo: this.editFormObj.newOrder,
                        deliverRemark: this.editFormObj.deliverRemark,
                    };
                    AddDeliver(args).then(res => {
                        if (res.errno == 0) {
                            ElMessage.success("处理成功");
                            showDlg.value = false;
                            this.editForm.value.resetFields();
                            queryData();
                        }
                    });
                }
            });
        };
        this.onCancel = () => {
            this.editForm.value.resetFields();
            showDlg.value = false;
        };
        this.statusFormatter = (row, col, cellValue) => {
            return cellValue == 1 ? '未发货' : '已处理';
        };
    }
}
