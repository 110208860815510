import { defineComponent } from 'vue';
import { dealDlg, dealRemark, dealDeliverId, queryData } from '../Shipped';
import { updateDeliverRemark } from '@/api/LogisticApi';
import { ElMessage } from 'element-plus';
export default defineComponent({
    setup() {
        const onSubmit = () => {
            dealDlg.value = false;
            updateDeliverRemark({
                deliverId: dealDeliverId.value,
                deliverRemark: dealRemark.value
            }).then(() => {
                ElMessage.success('处理说明更新成功');
                queryData();
            });
        };
        const onCancel = () => {
            dealDlg.value = false;
        };
        return {
            dealRemark,
            onSubmit,
            onCancel
        };
    }
});
